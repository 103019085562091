<template>
	<div>
		<!-- <showtime type="1"> </showtime> -->
		<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
			:options="playerOptions" @ended="onPlayerEnded($event)" @timeupdate="onPlayerTimeupdate($event)"
			@canplay="canplay($event)" @ready="playerReadied" @pause="videoPause" @play="videoPlay"></video-player>
		<div class="title">
			<div class="item active">课程目录</div>
			<!-- <div class="item active jl" @click="gettiming">记录学习时长</div> -->
		</div>
		<humanface :type="this.facetype" ref="chind"></humanface>
		<div class="active">
			<el-collapse v-if="viode_list.length > 0" v-model="activeNames">
				<el-collapse-item v-for="(item, index) in viode_list" :key="index" :title="item.title" :name="index">
					<div v-for="(item1, index1) in item.section" :key="index1">
						<div class="videoTitle">
							<div class="num">
								{{ index1 + 1 >= 10 ? index1 + 1 : "0" + (index1 + 1) }}
							</div>
							<div class="titleDoc"></div>
							<div>{{ item1.title }}</div>
						</div>
						<div class="videoList">
							<div v-for="(item2, index2) in item1.video"
								@click="tapVideo(item2, index2, 'yes', 1, index)" :key="index2" :class="
                  copy_list[activeIndex].id == item2.id
                    ? 'videoItem active'
                    : 'videoItem'
                ">
								<img v-if="item2.is_finished == 1 && item2.is_studied == 1" class="bof"
									src="../../assets/bof.png" alt="" />
								<div class="videoTxt">{{ item2.title }}</div>
								<img class="videoBtn" src="../../assets/videoBtn.png" alt="" />
							</div>
						</div>
					</div>
				</el-collapse-item>
			</el-collapse>
			<div class="none" v-else>暂无视频</div>
		</div>
		<div style="height: 500px"></div>
		<div class="cameraImg" style="display: none">
			<div style="display: none">
				<canvas width="200px" height="150px" ref="canvas"></canvas>
			</div>
		</div>

		<div class="mask" v-show="flagss">
			<div>
				<img class="imgs" src="../../assets/icon.png" alt="" />
				<div class="popup">
					<div class="title1">人脸识别认证</div>
					<div class="content1">
						在您的学习过程中我们将对您进行学习身份进行核验，届时会人脸识别的方式进行核验。
					</div>
					<el-checkbox style="margin-left: 30px" v-model="checked">同意《职培通在线教育云平台学员核验规定》</el-checkbox>
					<div class="dian" @click="normal1">开始认证</div>
				</div>
			</div>
		</div>

		<div class="human_mask" v-show="flags">
			<div>
				<img class="imgs" src="../../assets/icon.png" alt="" />
				<div class="popup">
					<div class="title1">人脸识别</div>
					<video class="head" ref="video"></video>
					<div class="head_1"></div>
					<div class="dian" id="snap" @click="getImg">开始核验</div>
				</div>
			</div>
		</div>

		<!-- //时间停止计时警告 -->
		<div class="stop_mask" v-if="flag2">
			<div>
				<img class="imgs" src="../../assets/icon.png" alt="" />
				<div class="popup">
					<div class="title1">
						<span>亲！</span> 您当前页面学习时长已停止记录<br /><span class="span">
						</span>
					</div>
					<div class="xian"></div>
					<div class="dian" @click="goon">点我继续计时</div>
				</div>
				<img class="img" @click="goon" src="../../assets/close.png" alt="" />
			</div>
		</div>
		<!-- 活跃度弹窗 -->
		<div class="mask1" v-show="flag">
			<div>
				<img class="imgs" src="../../assets/icon.png" alt="" />
				<div class="popup">
					<div class="title1">
						<span>亲！</span> 你还在学习吗<br /><span class="span">如果在的话，请点我哦！</span>
					</div>
					<div class="xian"></div>
					<div class="dian" @click="normal">请点我</div>
				</div>
				<img class="img" src="../../assets/close.png" alt="" @click="normal" />
			</div>
		</div>
		<writetime type="1" :tz_time="tz_time" ref="child"></writetime>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from "vuex";
	import base from "../../libs/base";

	export default {
		data() {
			return {
				dialogVisible: false,
				type: 1,
				show: true,
				params: {
					class_id: "1", //班级id123
					student_id: "1", //学员id
					type: "1", //1. 视频 2. 音频
					page: 1,
					pageSize: 10,
				},
				total: 0,
				ledgDeta: {},
				active: 0,
				practicalList: [],
				time: null,
				playerOptions: {
					playbackRates: [1.0], //播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					techOrder: ["html5"],
					flash: {
						hls: {
							withCredentials: false
						}
					}, // 可以使用rtmp视频
					html5: {
						hls: {
							withCredentials: false
						}
					}, // 可以使用m3u8视频
					isFullscreen: true,
					sources: [
						// {
						//   src: "", // 路径
						//   type: "video/mp4", // 类型
						// },
					],
					poster: "", //你的封面地址
					// width: document.documentElement.clientWidth,
					notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						controls: false,
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true, //全屏按钮
					},
				},

				checked: false,
				socket: null, //socket对象
				setinter: null, //循环发送消息（5秒）
				socketLoad: null, //socket连接成功
				lock: true, //禁止多次调用
				time: null,
				dynamic_time: null,
				start_time: "",
				respond_time: "",
				flag1: true,
				flag2: false,
				num: 1,
				leveTime: null, //离开页面时间
				backTime: null, //返回页面时间
				begin_time: null,
				reset: true,
				human_time: null,
				Socket_sta: null,
				Socket_end: null,
				screen: true, //切屏停止计时
				flags: false,
				flagss: false,
				flag: false,
				tz_time: 0,
				timeString: 0, //当前进度时间
				timeOld: 0, //最长观看视频时间
				present: 1, // 1需要跳转指定位置
				activeIndex: 0,
				practical: 0,
				practical_flag: true,
				copy_list: [],
				viode_str: 0, //视频播放开始时间
				timing_flag: null,
				activeNames: "",
				viode_list: [],
				watch_num: 0,
				duantime: 5 * 60 * 1000,
				postzttime: 0,
				posttimelist: [],
				pauseflag: true,
				playlag: false,
				watchtime: null,
				timeendtype: false,
				zbbtype: false,
				zbbtime: null,
				zttimetype: false,
				facetype: 0,
			};
		},
		computed: {
			...mapState({
				userInfo: (state) => state.userInfo,
				classid: (state) => state.classid,
				url: (state) => state.url,
				school: (state) => state.school,
				studyType: (state) => state.studyType,
				details: (state) => state.details,
				det: (state) => state.det,
				human: (state) => state.human,
				video_shoot: (state) => state.video_shoot,
			}),
		},
		methods: {
			handleClose() {},
			...mapActions([
				"getVideoList",
				"getVideoAudioCallback",
				"getKnowledgeList",
				"getKnowledgeDetail",
				"getSetKnowledgeStatus",
				"getknowledgeList_x",
				"getinsStudyRecord_x",
				"screenCuttingDuration_x",
				"getactivityDetection",
				"getpictureUpload_x",
				"faceAuth",
				"ChapterSectionList",
				"cumulativeTime",
				"getrmCache",
				"getstudyReport_x",
				"getFaceRecognition",
				"getfaceRecognition",
			]),
			gettiming() {
				this.listtime();
				let that = this;
				that.$refs.child.timing();
				this.screenCuttingDuration_x({
					class_id: this.classid, //班级id
					user_id: this.userInfo.id, //学员id
					video_time: that.postzttime,
					source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
					video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
					is_finished: "2", //是否学完 1-完成 2-未完成
					last_viewing_time: this.timeOld.toFixed(0),
					sub_video_time: {
						video_time: this.inittime(
							this.playerOptions.sources[this.activeIndex].video_time
						), //视频时长
						video_close_time: this.timeOld.toFixed(0),
						video_start_time: this.viode_str.toFixed(0),
					}, //副表参数
				}).then((res) => {
					that.viode_str = that.timeString;
					if (res.successCode == 200) {
						this.postjltime();
						that.$notify({
							title: "提示",
							message: "视频详细时间记录成功",
							type: "success",
						});
					}
				});
			},
			canplay(player) {
				this.player = player;
			},
			// 获取当前播放进度;
			onPlayerTimeupdate(player) {
				if (this.zttimetype) {
					this.zttimetype = false;
					this.posttimelist.push(Number(this.timeOld.toFixed(0)));
					player.currentTime(this.timeOld);
				}
				this.practical += 0.265; // 2月17号修改
				if (this.copy_list[this.activeIndex].is_finished == 2) {
					if (this.present == 1) {
						this.timeString = player.cache_.currentTime;
						this.present = 2;
						player.currentTime(this.timeOld);
						return;
					}
					if (player.cache_.currentTime - this.timeString > 3) {
						if (this.userInfo.type != 2) {
							if (player.cache_.currentTime >= this.timeOld) {
								this.timeString = this.timeOld;
								player.currentTime(this.timeOld);
								return;
							} else {
								this.timeString = player.cache_.currentTime;
								player.currentTime(this.timeString);
								return;
							}
						}
					}

					this.timeString = player.cache_.currentTime;
					if (this.timeString > this.timeOld) {
						this.timeOld = player.cache_.currentTime;
					}
				} else {
					this.timeString = player.cache_.currentTime;
					if (this.timeString > this.timeOld) {
						this.timeOld = player.cache_.currentTime;
					}
				}
			},
			// 认证人脸识别
			normal1() {
				if (this.checked == false) {
					this.$message({
						message: "请勾选协议",
						type: "warning",
					});
				} else {
					this.flagss = false;
					this.openCamera();
					this.flags = true;

					return;
				}
			},
			//掉用摄像头进行人脸识别
			openCamera() {
				this.$nextTick(() => {
					var video = this.$refs.video;
					this.video = video;
					if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
						navigator.mediaDevices
							.getUserMedia({
								video: true,
							})
							.then(function(stream) {
								MediaStreamTrack =
									typeof stream.stop === "function" ?
									stream :
									stream.getTracks()[1];
								video.srcObject = stream;
								video.play();
							})
							.catch(function(err) {
								// console.log(err);
							});
					} else if (navigator.getMedia) {
						navigator
							.getMedia({
								video: true,
							})
							.then(function(stream) {
								console.log(stream);
								MediaStreamTrack = stream.getTracks()[1];
								video.src = window.webkitURL.createObjectURL(stream);
								video.play();
							})
							.catch(function(err) {
								console.log(err);
							});
					}
				});
			},
			/* 设置视频进度 */
			playerReadied(player) {
				// player.currentTime(50);
			},
			//完成播放进行时长上传
			onPlayerEnded() {
				this.posttimelist.push(
					Number(
						this.inittime(this.playerOptions.sources[this.activeIndex].video_time)
					)
				);
				this.timeendtype = true;
				console.log(this.posttimelist, "进行push");
				this.pauseflag = false;
				let that = this;
				this.copy_list[this.activeIndex].is_finished = 1; //改变播放状态
				this.fornodata(
					this.playerOptions.sources[this.activeIndex],
					this.viode_list
				); //改变播放状态
				if (this.activeIndex == this.playerOptions.sources.length - 1) {
					this.listtime();
					this.screenCuttingDuration_x({
						class_id: this.classid, //班级id
						user_id: this.userInfo.id, //学员id
						video_time: that.postzttime,
						source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
						video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
						is_finished: 1, //是否学完 1-完成 2-未完成
						last_viewing_time: this.inittime(
							this.playerOptions.sources[this.activeIndex].video_time
						),
						end_time: new Date(+new Date() + 8 * 3600 * 1000)
							.toJSON()
							.substr(0, 19)
							.replace("T", " "),
						sub_video_time: {
							video_time: this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							), //视频时长
							video_close_time: this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							),
							video_start_time: this.viode_str.toFixed(0),
						}, //副表参数
					}).then((res) => {
						if (this.userInfo.type == 2) return;
						if (res.responseBody == 99) {
							this.fornodata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.$message.error("课时已满8课时");
							this.$router.go(-1);
						} else if (res.responseBody == 88) {
							this.fornodata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.$message.error("请完整观看完视频，否则不于记录");
							this.cumulativeTime({
								user_id: this.userInfo.id,
								class_id: this.classid,
								video_id: this.playerOptions.sources[this.activeIndex].id,
							}).then((res) => {
								if (re.successCode == 200) {
									this.postjltime();
									this.zttimetype = true;
									this.fornodata(
										this.playerOptions.sources[this.activeIndex],
										this.viode_list
									);
									let str =
										this.inittime(
											this.playerOptions.sources[this.activeIndex].video_time
										) - re.responseBody.video_time;
									this.$vux.toast.show({
										type: "cancel",
										text: `时长还差${str}秒，请继续观看`,
									});
									this.present = 1;
									this.timeOld = re.responseBody.video_time - 10;
								}
							});
						} else if (res.responseBody == 66) {
							this.$message.error(res.message);
							this.fornodata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.present = 1;
							this.timeOld = 0;
						}
					});
					return;
				}
			},
			//canvas中展示截取的图像
			getImg() {
				let that = this;
				var canvas = this.$refs.canvas;
				var context = canvas.getContext("2d");
				context.drawImage(this.video, 0, 0, 200, 150);
				var image = canvas.toDataURL("image/png");
				var timestamp = Date.parse(new Date()); //获取时间戳，防止图像重名
				var img = this.dataURLtoFile(image, timestamp + ".png"); //使用png的图像进行名称拼接
				console.log(img, "this.imageUrl");
				// this.video.srcObject.getTracks()[0].stop();
				// return;
				var imgBase64;
				var reader = new FileReader();
				reader.readAsDataURL(img);
				reader.onload = function() {
					imgBase64 = this.result; //获取到base64格式图片
					console.log("imgBase64", imgBase64);
					that
						.getpictureUpload_x({
							img: imgBase64,
						})
						.then((res) => {
							if (res.successCode == 200) {
								that
									.faceAuth({
										student_id: that.userInfo.id, //用户名
										image: res.responseBody, //人脸图片
									})
									.then((ress) => {
										if (ress.successCode == 200) {
											that.$store.commit("STE_HUMAN", 0);
											that.video.srcObject.getTracks()[0].stop();
											that.flags = false;
										} else {
											that.$message.error(ress.message);
											setTimeout(() => {
												that.video.srcObject.getTracks()[0].stop();
												that.$router.push("/Layout/mynterpretation");
											}, 1000);
										}
									});
							}
						});
				};
			},

			//图片转base64位
			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				var file = new File([u8arr], filename, {
					type: mime
				});
				return new File([u8arr], filename, {
					type: mime
				});
			},
			//更改实训考点状态
			getStatus() {
				this.getSetKnowledgeStatus({
					id: this.ledgDeta.id,
					username: this.userInfo.username,
					classid: this.classid,
					status: 2,
				}).then((res) => {
					this.$message({
						message: res.msg,
						type: res.code == 200 ? "success" : "cancel",
					});
					if (res.code == 200) {
						this.practicalList = this.practicalList.map((e) => {
							if (e.id == this.ledgDeta.id) {
								e.status = 2;
							}
							return e;
						});
						this.dialogVisible = false;
					}
				});
			},
			setClick(index, item) {
				if (this.studyType == 2 && index > 0) {
					this.$message({
						message: "请购买课程",
						type: "warning",
					});
					return;
				}
				this.practicalList = this.practicalList.map((e) => {
					e.selected = false;
					return e;
				});
				item.selected = true;
				this.getKnowledgeDetail({
					username: this.userInfo.username,
					classid: this.classid,
					id: item.id,
				}).then((res) => {
					if (res.code == 200) {
						this.dialogVisible = true;
						this.ledgDeta = {
							...res.data,
							...item
						};
					} else {
						this.$message({
							message: res.msg,
							type: "error",
						});
					}
				});
			},
			fordata(olddata, newdata) {
				for (let j = 0; j < newdata.length; j++) {
					newdata[j].section.forEach((item, index) => {
						for (let i = 0; i < item.video.length; i++) {
							if (item.video[i].id == olddata.id) {
								item.video[i].is_finished = 1;
								item.video[i].is_studied = 1;
							}
						}
					});
				}
			},
			fornodata(olddata, newdata) {
				for (let j = 0; j < newdata.length; j++) {
					newdata[j].section.forEach((item, index) => {
						for (let i = 0; i < item.video.length; i++) {
							if (item.video[i].id == olddata.id) {
								item.video[i].is_finished = 2;
								item.video[i].is_studied = 1;
								console.log("有id", item.video[i]);
							}
						}
					});
				}
			},
			//开始播放视频，进行时长上传
			async tapVideo(item, index, start, type, index1) {
				let type11 = window.localStorage.getItem("classtype");
				let face = window.localStorage.getItem("fasetype");
				if (type11 == 3) {
					if (face == 1) {
						this.getschedule();
						this.flagss = true;
					}
				} else {
					this.flagss = false;
				}
				let that = this;
				if (this.timeendtype) {
					this.player.cache_.currentTime = this.inittime(
						this.playerOptions.sources[this.activeIndex].video_time
					);
				}
				if (this.player) {
					if (
						this.player.cache_.currentTime.toFixed(0) >=
						this.inittime(this.playerOptions.sources[this.activeIndex].video_time)
					) {
						status = "yes";
						this.playerOptions.sources[this.activeIndex].is_finished = 1;
						this.fordata(
							this.playerOptions.sources[this.activeIndex],
							this.viode_list
						);
					} else {
						status = "no";
						console.error(1);
					}
				}
				console.error(
					this.timeOld,
					this.player.cache_.currentTime.toFixed(0),
					this.inittime(this.playerOptions.sources[this.activeIndex].video_time),
					status
				);
				if (this.studyType == 2 && index > 0) {
					this.$message({
						message: "请购买课程",
						type: "warning",
					});
					return;
				}
				// indexs //对应copy_list数组下标
				let indexs = this.copy_list.findIndex((items) => {
					return items.id == item.id;
				});

				// 相同集数不能重复点击
				if (type == 1) {
					if (indexs == this.activeIndex) return;
				}

				// 不能越级播放
				if (indexs > this.watch_num) {
					if (this.userInfo.type != 2) {
						let num = this.copy_list.findIndex((item) => {
							return item.is_finished == 2;
						});
						this.$message({
							message: `请完成前面(${this.copy_list[num].title})学习`,
							type: "warning",
						});
						return;
					}
				}

				if (start == "yes") {
					this.copy_list[this.activeIndex].is_finished = 1;
				} else {
					this.copy_list[this.activeIndex].viewed_time = this.timeOld;
				}
				const loading = this.$loading({
					lock: true,
					text: "正在拼命加载中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				//记录时间
				if (this.timeendtype) {
					loading.close();
					this.timeendtype = false;
					this.listtime();
					await this.screenCuttingDuration_x({
						class_id: this.classid, //班级id
						user_id: this.userInfo.id, //学员id
						video_time: that.postzttime,
						source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
						video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
						is_finished: start == "yes" ? 1 : 2, //是否学完 1-完成 2-未完成
						last_viewing_time: this.player.cache_.currentTime.toFixed(0) <
							this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							) ?
							this.player.cache_.currentTime.toFixed(0) :
							this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							),
						end_time: start == "yes" ?
							new Date(+new Date() + 8 * 3600 * 1000)
							.toJSON()
							.substr(0, 19)
							.replace("T", " ") :
							"",
						sub_video_time: {
							video_time: this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							), //视频时长
							video_close_time: this.player.cache_.currentTime.toFixed(0) <
								this.inittime(
									this.playerOptions.sources[this.activeIndex].video_time
								) ?
								this.player.cache_.currentTime.toFixed(0) :
								this.inittime(
									this.playerOptions.sources[this.activeIndex].video_time
								),
							video_start_time: this.viode_str.toFixed(0),
						}, //副表参数
					}).then((res) => {
						if (this.userInfo.type == 2) return;
						if (res.responseBody == 99) {
							this.postjltime();
							this.fornodata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.$message.error("课时已满8课时");
							this.$router.go(-1);
							return;
						} else if (res.responseBody == 88) {
							this.fornodata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.$message.error("课时记录异常，请重新观看");
							setTimeout(function() {
								that.$store.commit("SET_video_shoot_clear");
							}, 1000);
							if (start == "yes") {
								this.cumulativeTime({
									user_id: this.userInfo.id,
									class_id: this.classid,
									video_id: this.playerOptions.sources[this.activeIndex].id,
								}).then((re) => {
									if (re.successCode == 200) {
										this.postjltime();
										this.zttimetype = true;
										this.fornodata(
											this.playerOptions.sources[this.activeIndex],
											this.viode_list
										);
										let str =
											this.inittime(
												this.playerOptions.sources[this.activeIndex].video_time
											) - re.responseBody.video_time;
										this.$vux.toast.show({
											type: "cancel",
											text: `时长还差${str}秒，请继续观看`,
										});
										this.present = 1;
										this.timeOld = re.responseBody.video_time - 10;
									}
								});
							}
							return;
						} else if (res.responseBody == 66) {
							this.postjltime();
							this.fordata(
								this.playerOptions.sources[this.activeIndex],
								this.viode_list
							); //改变播放状态
							this.present = 1;
							this.timeOld = 0;
						} else {
							loading.close();
							if (this.playerOptions.sources[this.activeIndex].is_finished == 1) {
								this.playerOptions.playbackRates = [0.7, 1.0, 1.5, 2.0];
							} else {
								this.playerOptions.playbackRates = [1.0];
							}
							this.activeIndex = indexs;
							this.postjltime();
							this.timeendtype = false;
							if (this.$refs.videoPlayer) {
								setTimeout(() => {
									this.$refs.videoPlayer.player.pause();
									this.$refs.videoPlayer.player.src(
										this.playerOptions.sources[indexs].video
									);
									this.$refs.videoPlayer.player.play();
									setTimeout(() => {
										this.$refs.videoPlayer.player.play();
									});
								}, 1000);
							}
						}
					});
				} else {
					loading.close();
					if (this.playerOptions.sources[this.activeIndex].is_finished == 1) {
						this.playerOptions.playbackRates = [0.7, 1.0, 1.5, 2.0];
					} else {
						this.playerOptions.playbackRates = [1.0];
					}
					this.activeIndex = indexs;
					this.postjltime();
					this.timeendtype = false;
					if (this.$refs.videoPlayer) {
						setTimeout(() => {
							this.$refs.videoPlayer.player.pause();
							this.$refs.videoPlayer.player.src(
								this.playerOptions.sources[this.activeIndex].video
							);
							this.$refs.videoPlayer.player.play();
							setTimeout(() => {
								this.$refs.videoPlayer.player.play();
							});
						}, 1000);
					}
				}
			},
			//格式化时间
			inittime(time) {
				var s = "";
				var hour = time.split(":")[0];
				var min = time.split(":")[1];
				var sec = time.split(":")[2];
				s = Number(hour * 3600) + Number(min * 60) + Number(sec);
				return s;
			},
			//记录
			postjltime() {
				this.getinsStudyRecord_x({
					class_id: this.classid, //班级id
					student_id: this.userInfo.id, //学员id
					courseware_id: this.playerOptions.sources[this.activeIndex].id, //音频/视频/h5 id
					type: "1", //1. 视频 2. 音频 3.h5
				});
			},
			//视频列表加载
			getVideo() {
				//视频
				this.getknowledgeList_x(this.params).then((res) => {
					if (res.successCode == 200) {
						var list = [];
						res.responseBody.knowledge.list.forEach((e) => {
							list.push({
								...e,
								src: e.video.indexOf("http") > -1 ? e.video : this.url + e.video,
								type: "",
							});
						});
						this.playerOptions.sources.push(...list);
						this.copy_list = JSON.parse(
							JSON.stringify(this.playerOptions.sources)
						);
						if (res.responseBody.knowledge.list.length == 10) {
							this.show = true;
						} else {
							this.show = false;
						}
						setTimeout(() => {
							if (this.params.page == 1) {
								if (this.playerOptions.sources[0].is_finished == 2) {
									// this.stop1();
									// this.playerOptions.playbackRates = [1.0];
									this.present = 1;
									this.timeOld = this.copy_list[this.activeIndex].viewed_time; //最长观看视频时间
									this.viode_str = this.copy_list[this.activeIndex].viewed_time;
									this.timeString = 0; //最长观看视频时间
									this.tapVideo(this.playerOptions.sources[0], 0);
								} else {
									this.tapVideo(this.playerOptions.sources[0], 0);
								}
							} else {
								this.tapVideo(
									this.playerOptions.sources[this.activeIndex],
									this.activeIndex
								);
							}
						}, 1000);

						// this.total = res.data.allpage;
					} else {
						this.$message.error(res.message);
						// this.total = this.params.page;
					}
				});
			},
			//记录考点信息
			init() {
				//实训考点
				this.getKnowledgeList({
					username: this.userInfo.username,
					classid: this.classid,
				}).then((res) => {
					if (res.code == 200) {
						this.practicalList = res.data;
					}
				});
			},
			//WebSocket 记录时长以弃用
			socketInit() {
				this.socket = new WebSocket(base.socketUrl);
				var that = this;

				this.Socket_sta = new Date();
				this.socket.onopen = function() {
					//补回连接中的时间段
					that.Socket_end = new Date();
					var date3 = that.Socket_end.getTime() - that.Socket_sta.getTime();
					that.screenCuttingDuration_x({
						username: that.userInfo.username,
						user_id: that.userInfo.id,
						class_id: that.classid,
						video_time: (date3 / 1000).toFixed(0),
						source: 1,
					});
					// }
					console.log("连接开始");
					that.socketLoad = true;
				};
			},
			//WebSocket 记录时长以弃用
			sendSocketMsg() {
				this.time = setInterval(() => {
					this.lock = true;
					if (this.socketLoad && this.screen) {
						this.socket.send(
							JSON.stringify({
								type: 2,
								classId: this.classid,
								userId: this.userInfo.id,
								source: 1,
							})
						);
					}
				}, 5 * 60 * 1000);
			},
			// 播放视频
			getdocument() {
				let that = this;
				if (document.hidden) {
					that.$refs.videoPlayer.player.pause();
				} else {}
			},
			normal() {
				clearTimeout(this.dynamic_time);
				this.respond_time = new Date(+new Date() + 8 * 3600 * 1000)
					.toJSON()
					.substr(0, 19)
					.replace("T", " ");
				this.getactivityDetection({
					username: this.userInfo.username,
					student_id: this.userInfo.id,
					status: 1,
					source: 2,
					start_time: this.start_time,
					respond_time: this.respond_time,
				});
				this.flag = false;
				this.$refs.videoPlayer.player.play();
				// 停止计时
				//   this.flag2 = false;
			},
			dynamic() {
				let that = this;
				that.flag = true; //显示弹窗
				if (that.player.isFullscreen_) {
					//    退出全屏
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.oRequestFullscreen) {
						document.oCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						document.webkitExitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else {
						window.parent.showTopBottom();
					}
				}
				that.$refs.videoPlayer.player.pause(); //暂停
				that.start_time = new Date(+new Date() + 8 * 3600 * 1000)
					.toJSON()
					.substr(0, 19)
					.replace("T", " ");
				that.dynamic_time = setTimeout(() => {
					that.getactivityDetection({
						username: that.userInfo.username,
						student_id: that.userInfo.id,
						status: 2,
						source: 2,
						start_time: that.start_time,
					});
					that.flag = false;
					if (!that.flag) {
						this.zbbtype = true;
						this.listtime();
						this.screenCuttingDuration_x({
							class_id: this.details.id, //班级id
							user_id: this.userInfo.id, //学员id
							video_time: 0, //视频观看时长
							last_viewing_time: this.viode_str.toFixed(0),
							source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
							video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
							is_finished: this.player.cache_.currentTime.toFixed(0) >=
								this.inittime(
									this.playerOptions.sources[this.activeIndex].video_time
								) ?
								1 :
								2, //是否学完 1-完成 2-未完成
							sub_video_time: {
								video_time: this.inittime(
									this.playerOptions.sources[this.activeIndex].video_time
								), //视频时长
								video_close_time: this.viode_str.toFixed(0),
								video_start_time: this.viode_str.toFixed(0) ?
									this.viode_str.toFixed(0) :
									0,
							},
						}).then((res) => {
							if (res.successCode == 200) {
								clearTimeout(this.dynamic_time);
								this.player.currentTime(this.viode_str.toFixed(0));
								setTimeout(() => {
									that.$notify({
										type: "success",
										message: "视频详细时间记录成功",
										duration: 1500,
									});
								}, 1500);
							}
						});
					}
				}, this.duantime);
			},
			// 暂停
			videoPause(item) {
				// var time = null;
				// if (this.pauseflag) {
				//   this.posttimelist.push(
				//     Number(this.player.cache_.currentTime.toFixed(0))
				//   );
				//   this.playlag = true;
				//   this.pauseflag = false;
				//   this.jlsj();
				//   time = setTimeout(() => {
				//     this.pauseflag = true;
				//   }, 1 * 60 * 1000);
				// }
			},
			// 播放
			videoPlay() {
				if (this.zbbtype == true) {
					this.zbbtype = false;
					this.postjltime();
				}
				// var time = null;
				// if (this.playlag) {
				//   this.posttimelist.push(
				//     Number(this.player.cache_.currentTime.toFixed(0))
				//   );
				//   this.playlag = false;
				//   this.jlsj();
				//   time = setTimeout(() => {
				//     this.playlag = true;
				//   }, 1 * 60 * 1000);
				// }
			},
			//离开监测
			goon() {
				clearTimeout(this.dynamic_time);
				this.flag2 = false;
			},
			listtime() {
				let index = this.posttimelist.length;
				if (this.posttimelist[index - 1] - this.posttimelist[index - 2] <= 0) {
					if (
						this.player.cache_.currentTime.toFixed(0) -
						this.viode_str.toFixed(0) !=
						0
					) {
						this.postzttime =
							this.player.cache_.currentTime.toFixed(0) -
							this.viode_str.toFixed(0);
					} else {
						this.posttimelist.push(0);
						if (
							this.player.cache_.currentTime.toFixed(0) != 0 &&
							this.viode_str.toFixed(0) != 0
						) {
							this.postzttime = 1;
						} else {
							this.postzttime = 0;
						}
						this.viode_str = 0;
					}
				} else {
					this.postzttime =
						this.posttimelist[index - 1] - this.posttimelist[index - 2];
					this.viode_str = this.posttimelist[index - 2];
				}
			},
			// 每5分钟调用一次这个事件 上传视频记录
			jlsj() {
				this.listtime();
				this.screenCuttingDuration_x({
					class_id: this.details.id, //班级id
					user_id: this.userInfo.id, //学员id
					video_time: this.postzttime, //视频观看时长
					last_viewing_time: this.player.cache_.currentTime.toFixed(0) <
						this.inittime(this.playerOptions.sources[this.activeIndex].video_time) ?
						this.player.cache_.currentTime.toFixed(0) :
						this.inittime(
							this.playerOptions.sources[this.activeIndex].video_time
						),
					source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
					video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
					is_finished: this.player.cache_.currentTime.toFixed(0) >=
						this.inittime(this.playerOptions.sources[this.activeIndex].video_time) ?
						1 :
						2, //是否学完 1-完成 2-未完成
					sub_video_time: {
						video_time: this.inittime(
							this.playerOptions.sources[this.activeIndex].video_time
						), //视频时长
						video_close_time: this.player.cache_.currentTime.toFixed(0) <
							this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							) ?
							this.player.cache_.currentTime.toFixed(0) :
							this.inittime(
								this.playerOptions.sources[this.activeIndex].video_time
							),
						video_start_time: this.viode_str.toFixed(0),
					}, //副表参数
				}).then((res) => {
					if (res.successCode == 200) {
						this.postjltime();
					}
				});
			},
			//暂停播放
			stop1() {
				let that = this;
				setTimeout(() => {
					that.$nextTick(() => {
						that.$refs.videoPlayer.player.play();
						// let a = document.getElementsByClassName("vjs-progress-control")[0];
						// a.style.pointerEvents = "none";
					});
				}, 100);
			},
			//开始播放
			play1() {
				let that = this;
				setTimeout(() => {
					that.$nextTick(() => {
						that.$refs.videoPlayer.player.play();
						// let a = document.getElementsByClassName("vjs-progress-control")[0];
						// a.style.pointerEvents = "auto";
					});
				}, 100);
			},
			// 判断是否符合人脸
			getschedule() {
				this.getstudyReport_x({
					student_id: this.userInfo.id,
					class_id: this.details.id,
				}).then((res) => {
					let classHour = res.responseBody.classHour;
					var reg1 = new RegExp("%", ""); // 加'g'，删除字符串里所有的"a"
					var numclassHour = Number(classHour.replace(reg1, ""));
					console.log(typeof numclassHour);
					if (numclassHour >= 31 && numclassHour <= 60) {
						this.facetype = 4;
					} else if (numclassHour >= 61 && numclassHour <= 100) {
						this.facetype = 5;
					} else if (numclassHour >= 0 && numclassHour <= 31) {
						this.facetype = 3;
					}
					if (this.facetype != 0) {
						this.getFaceRecognition({
							student_id: this.userInfo.id,
							genre: this.facetype, //1. 学习核验 2. 考试核验
							class_id: this.details.id,
						}).then((res) => {
							if (res.errorCode == 399) {
								this.$refs.videoPlayer.player.pause();
								this.$refs.chind.control();
							}
						});
					}
				});
			},
			//视频列表
			getNewList() {
				this.$store.commit("SET_video_shoot_clear");
				this.getrmCache({
					student_id: this.userInfo.id,
					page: "1", //当前页
					pageSize: "100", //一页显示数
				}).then((res) => {});
				let viode_list = [];
				this.ChapterSectionList({
					class_id: this.params.class_id, //班级id
					student_id: this.params.student_id, //学员id
					type: "1", //1. 视频 2. 音频
				}).then((res) => {
					if (res.successCode == 200) {
						res.responseBody.knowledge.forEach((item, index) => {
							if (index == 0) {
								item.selected = true;
							}
							item.section.forEach((item1, index1) => {
								item1.video.forEach((item2, index2) => {
									viode_list.push({
										...item2,
										src: item2.video.indexOf("http") > -1 ?
											item2.video :
											this.url + item2.video,
										type: "",
									});
								});
							});
						});
						this.viode_list = res.responseBody.knowledge;
						this.playerOptions.sources = viode_list;
						let video_num = 0;
						let continuous_num = 0;
						viode_list.forEach((item) => {
							if (item.is_finished == 1) {
								if (continuous_num == 0) {
									video_num += 1;
								}
							} else {
								continuous_num += 1;
							}
						});

						if (video_num == viode_list.length) {
							//赋值最长可观看数
							this.watch_num = video_num;
							//赋值当前视频下标
							this.activeIndex = 0;
						} else {
							//赋值最长可观看数
							this.watch_num = video_num;
							//赋值当前视频下标
							this.activeIndex = video_num;
						}

						//不能改变播放器原数组，否则导致播放器重置
						this.copy_list = JSON.parse(
							JSON.stringify(this.playerOptions.sources)
						);
						this.$refs.videoPlayer.$el.firstChild
							.getElementsByClassName("vjs-control-bar")[0]
							.getElementsByClassName(
								"vjs-progress-control"
							)[0].style.pointerEvents = "none";

						if (this.playerOptions.sources[this.activeIndex].is_finished == 2) {
							this.present = 1;
							if (this.video_shoot.length == 0) {
								this.timeOld =
									this.copy_list[this.activeIndex].viewed_time >
									this.inittime(this.copy_list[this.activeIndex].video_time) ?
									this.inittime(this.copy_list[this.activeIndex].video_time) -
									10 :
									this.copy_list[this.activeIndex].viewed_time;
								this.viode_str =
									this.copy_list[this.activeIndex].viewed_time >
									this.inittime(this.copy_list[this.activeIndex].video_time) ?
									this.inittime(this.copy_list[this.activeIndex].video_time) -
									10 :
									this.copy_list[this.activeIndex].viewed_time;
							} else {
								//有数据判断是否有当前视频ID的数据缓存
								let shoot = this.video_shoot.findIndex((item) => {
									return (
										item.id == this.playerOptions.sources[this.activeIndex].id
									);
								});
								//-1没有查找到走接口赋值，有的话取缓存数据里面赋值
								if (shoot == -1) {
									this.timeOld =
										this.copy_list[this.activeIndex].viewed_time >=
										this.inittime(this.copy_list[this.activeIndex].video_time) ?
										this.inittime(
											this.copy_list[this.activeIndex].video_time
										) - 10 :
										this.copy_list[this.activeIndex].viewed_time;
									this.viode_str =
										this.copy_list[this.activeIndex].viewed_time >=
										this.inittime(this.copy_list[this.activeIndex].video_time) ?
										this.inittime(
											this.copy_list[this.activeIndex].video_time
										) - 10 :
										this.copy_list[this.activeIndex].viewed_time;
								} else {
									this.timeOld =
										parseInt(this.video_shoot[shoot].time) >=
										this.inittime(this.copy_list[this.activeIndex].video_time) ?
										this.inittime(
											this.copy_list[this.activeIndex].video_time
										) - 10 :
										parseInt(this.video_shoot[shoot].time);
									this.viode_str =
										parseInt(this.video_shoot[shoot].time) >=
										this.inittime(this.copy_list[this.activeIndex].video_time) ?
										this.inittime(
											this.copy_list[this.activeIndex].video_time
										) - 10 :
										parseInt(this.video_shoot[shoot].time);
								}
							}
							this.timeString = 0; //最长观看视频时间
						}
						this.posttimelist.push(Number(this.viode_str.toFixed(0)));
						this.playerOptions.sources[0] =
							this.playerOptions.sources[this.activeIndex];
						this.postjltime();
						this.tapVideo(
							this.playerOptions.sources[this.activeIndex],
							this.activeIndex
						);
					}
				});
			},
		},
		mounted() {
			let that = this;
			this.params.class_id = this.classid;
			this.params.student_id = this.userInfo.id;
			this.getNewList();
			document.addEventListener("visibilitychange", that.getdocument, false);
		},
		beforeDestroy() {
			let that = this;
			// this.socket.close();
			//此处关闭记录单个视频时间
			clearInterval(this.timing_flag);
			this.$store.commit("SET_video_shoot", {
				id: this.playerOptions.sources[this.activeIndex].id,
				time: this.player.cache_.currentTime.toFixed(0),
			});

			this.screenCuttingDuration_x({
				class_id: this.classid, //班级id
				user_id: this.userInfo.id, //学员id
				video_time: this.player.cache_.currentTime.toFixed(0) - this.viode_str.toFixed(0), //视频观看时长
				last_viewing_time: this.player.cache_.currentTime.toFixed(0),
				source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
				video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
				is_finished: "2", //是否学完 1-完成 2-未完成
				sub_video_time: {
					video_time: this.inittime(
						this.playerOptions.sources[this.activeIndex].video_time
					), //视频时长
					video_close_time: this.player.cache_.currentTime.toFixed(0),
					video_start_time: this.viode_str.toFixed(0),
				}, //副表参数
			}).then((res) => {
				if (res.successCode == 200) {
					this.$notify({
						title: "提示",
						message: "视频详细时间记录成功",
						type: "success",
					});
				}
			});
			//必须内存地址相同才能移出方法
			document.removeEventListener("visibilitychange", that.getdocument, false);
			this.flag1 = false;
			clearInterval(this.time);
			clearTimeout(this.human_time);
			clearTimeout(this.dynamic_time);
			clearTimeout(this.begin_time);
			this.video.srcObject.getTracks()[0].stop();
		},
		watch: {
			activeIndex(val, vals) {
				let that = this;
				this.timeString = 0;
				this.timeOld = 0;
				this.viode_str = 0;

				if (this.copy_list[val].is_finished != 2) {
					setTimeout(() => {
						that.play1();
					}, 150);
				} else {
					setTimeout(() => {
						that.present = 1;
						that.timeOld = that.copy_list[val].viewed_time; //最长观看视频时间
						that.timeString = 0; //最长观看视频时间
						that.viode_str = that.copy_list[val].viewed_time;
						that.stop1();
					}, 150);
				}
			},
			// 监听播放时间为40分钟倍数弹出活跃度检测
			practical: {
				handler(newval, oldval) {
					if (newval.toFixed(0) % 2400 == 0 && newval > 1) {
						clearTimeout(this.zbbtime);
						this.zbbtime = setTimeout(() => {
							this.dynamic();
						}, 1000);
					}
					if (newval.toFixed(0) % 153 == 0 && newval > 1) {
						clearTimeout(this.watchtime);
						this.watchtime = setTimeout(() => {
							this.posttimelist.push(
								Number(this.player.cache_.currentTime.toFixed(0))
							);
							this.jlsj();
						}, 1000);
					}
				},
				deep: true,
			},
			//监听视频所观看数
			copy_list: {
				handler(newval, newvals) {
					let num = newval.filter((item, index) => {
						return item.is_finished == 1;
					}).length;
					this.watch_num = num;
				},
				immediate: true,
				deep: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.videoList {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;

		.videoItem {
			margin-right: 10px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			width: 165px;

			height: 44px;
			background: #e9f3ff;
			border-radius: 8px;
			border: 2px solid #0039ff;
			cursor: pointer;

			.videoTxt {
				width: 100px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.bof {
				width: 19px;
				height: 19px;
				margin-right: 8px;
			}

			.videoBtn {
				width: 24px;
				height: 24px;
				margin-left: 8px;
			}
		}

		.active {
			background-color: #fcf1f0;
			border: 2px solid #eb5328 !important;
		}
	}

	.videoTitle {
		display: flex;
		align-items: center;

		.num {
			margin-right: 10px;
		}

		.titleDoc {
			width: 6px;
			height: 6px;
			border-radius: 10px;
			background: #0038ff;
			margin-right: 10px;
		}
	}

	.video-js .vjs-icon-placeholder {
		width: 100%;
		height: 100%;
		display: block;
	}

	.mask1 {
		position: fixed;
		z-index: 99;

		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;

		.imgs {
			display: block;
			width: 200px;
			margin: 0 auto;
			// vertical-align: middle;
		}

		.img {
			width: 40px;
			height: 40px;
			display: block;
			margin: 15px auto;
		}

		.popup {
			position: relative;
			z-index: 100;
			width: 227px;
			height: 143px;
			background: #ffffff;
			border-radius: 16px;

			.title1 {
				text-align: center;
				padding: 20px;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
				line-height: 26px;

				span {
					font-size: 18px;
				}

				.span {
					font-weight: 500;
					font-size: 16px;
					color: #000;
				}
			}

			.xian {
				width: 100%;
				height: 2px;
				background-color: #eaeaea;
			}

			.dian {
				cursor: pointer;
				margin-top: 15px;
				text-align: center;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
			}
		}
	}

	.human_mask {
		position: fixed;
		z-index: 99;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.popup {
			position: relative;
			width: 414px;
			height: 453px;
			background: #ffffff;
			box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.5);
			border-radius: 15px;

			.title1 {
				padding-top: 30px;
				text-align: center;
				font-size: 24px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #008bd5;
				line-height: 33px;
			}

			.head {
				display: block;
				margin: 0 auto;
				width: 200px;
				height: 200px;
				border-radius: 50%;
			}

			// .head_1 {
			//   position: absolute;
			//   width: 100px;
			//   height: 100px;
			//   left: 0;
			//   top: 0;
			// }
			.dian {
				border-top: 2px solid #eaeaea;
				position: absolute;
				left: 0;
				bottom: 0;
				height: 50px;
				width: 100%;
				line-height: 50px;
				margin-top: 15px;
				text-align: center;

				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
				cursor: pointer;
			}
		}
	}

	.stop_mask {
		position: fixed;
		z-index: 99;

		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;

		.imgs {
			display: block;
			width: 200px;
			margin: 0 auto;
			// vertical-align: middle;
		}

		.img {
			width: 40px;
			height: 40px;
			display: block;
			margin: 15px auto;
		}

		.popup {
			position: relative;
			z-index: 100;
			width: 227px;
			height: 143px;
			background: #ffffff;
			border-radius: 16px;

			.title1 {
				text-align: center;
				padding: 20px;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
				line-height: 26px;

				span {
					font-size: 18px;
				}

				.span {
					font-weight: 500;
					font-size: 16px;
					color: #000;
				}
			}

			.xian {
				width: 100%;
				height: 2px;
				background-color: #eaeaea;
			}

			.dian {
				cursor: pointer;
				margin-top: 15px;
				text-align: center;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
			}
		}
	}

	.mask {
		position: fixed;
		z-index: 99;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.imgs {
			display: block;
			width: 300px;
			margin: 0 auto;
			// vertical-align: middle;
		}

		.popup {
			position: relative;
			z-index: 100;
			width: 414px;
			height: 377px;
			background: #ffffff;
			border-radius: 16px;

			.title1 {
				padding-top: 30px;
				text-align: center;
				font-size: 24px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #008bd5;
				line-height: 33px;
			}

			.content1 {
				margin: 30px;
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #222222;
			}

			.dian {
				border-top: 2px solid #eaeaea;
				position: absolute;
				left: 0;
				bottom: 0;
				height: 50px;
				width: 100%;
				line-height: 50px;
				margin-top: 15px;
				text-align: center;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0067ff;
				cursor: pointer;
			}
		}
	}

	// .loadmore {
	//   cursor: pointer;
	//   font-size: 16px;
	//   text-align: center;
	// }
	.title {
		margin-top: 40px;
		height: 60px;
		line-height: 60px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		border-bottom: 1px solid #e1e1e1;
		margin-bottom: 26px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.item {
			cursor: pointer;
			position: relative;
			// margin-right: 52px;
		}

		.active {
			color: #137cfb;
		}

		.jl {
			width: 120px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 12px;
			background-color: #dae1fc;
			border-radius: 15px;
			border: 1px solid #137cfb;
		}
	}

	.list {
		display: flex;
		flex-wrap: wrap;

		.active {
			color: #137cfb !important;
			border: 2px solid #137cfb !important;
			background: #f5fbff;
		}

		.item {
			cursor: pointer;
			position: relative;
			margin-bottom: 17px;
			margin-right: 18px;
			padding: 10px;
			box-sizing: border-box;
			width: 160px;
			height: 75px;
			background: #fff;
			border-radius: 8px;
			border: 2px solid #e7e7e7;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			overflow: hidden;
			line-height: 20px;

			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}

		.wc {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.none {
		color: #999;
		text-align: center;
		font-size: 14px;
		line-height: 32px;
	}
</style>